import React, { createContext, useState, useEffect } from "react";
import { initializeRemoteConfig } from "../services/firebase-remote-config";

type RemoteConfigContextType = {
  initialized: boolean;
  failedToInitialize: boolean;
};

const defaultRemoteConfigContext: RemoteConfigContextType = {
  initialized: false,
  failedToInitialize: false,
};

export const RemoteConfigContext = createContext<RemoteConfigContextType>(
  defaultRemoteConfigContext,
);

export const RemoteConfigProvider = ({ children }: any) => {
  const [
    config,
    setConfig,
  ] = useState<RemoteConfigContextType>(defaultRemoteConfigContext);

  useEffect(() => {
    const maxRetries = 3;
    let retryCount = 0;
    let retryDelay = 100;

    async function fetchConfig() {
      const remoteConfig = await initializeRemoteConfig();
      if (remoteConfig) {
        setConfig({
          ...config,
          initialized: remoteConfig,
        });
      } else if (retryCount < maxRetries) {
        console.error("Failed to initialize remote config. Retrying...");
        setTimeout(fetchConfig, retryDelay);
        retryCount++;
        retryDelay *= 2;
      } else {
        console.error("Failed to initialize remote config after multiple retries.");
        setConfig({
          ...config,
          failedToInitialize: true,
        });
      }
    }

    fetchConfig();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RemoteConfigContext.Provider value={config}>
      {children}
    </RemoteConfigContext.Provider>
  );
};
