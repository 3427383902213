import axios from "axios";
import { IP_GEOLOCATION_API_KEY } from "../util/variables";
export interface GeolocationI {
  country: string;
  country_code2: string;
  ip: string;
  currency: {
    code: string;
    name: string;
    symbol: string;
  };
  is_eu: boolean;
}

export const getGeolocation = async (): Promise<GeolocationI> => {
  // https://ipgeolocation.io/documentation/ip-geolocation-api.html
  const response = await axios.get(
    `https://api.ipgeolocation.io/ipgeo?apiKey=${IP_GEOLOCATION_API_KEY}&fields=country_name,country_code2,currency,is_eu`,
  );
  return response.data;
};
