import * as images from "../img/onboarding/index";
import { Onboarding1, Onboarding2, Onboarding3 } from "../assets";
import SecondRule from "../img/onboarding/books/5-sec-rule.webp";
import SevenHabits from "../img/onboarding/books/7-habits.webp";
import AtomicHabits from "../img/onboarding/books/atomic-habits.webp";
import BeginnersGuide from "../img/onboarding/books/BGTSM.webp";
import CrucialConversations from "../img/onboarding/books/crucial-conversations.webp";
import EmotionalIntelligence from "../img/onboarding/books/emotional-intelligence.webp";
import EssentialPhilosophy from "../img/onboarding/books/essential-philosophy.webp";
import Flow from "../img/onboarding/books/flow.webp";
import FreudianMind from "../img/onboarding/books/freudian-mind.webp";
import GunsGermsSteel from "../img/onboarding/books/guns-germs-and-steel.webp";
import GutBacteria from "../img/onboarding/books/gut-bacteria-eats.webp";
import HomoDeus from "../img/onboarding/books/homo-deus.webp";
import WinFriends from "../img/onboarding/books/how-to-win-friends.webp";
import DefenseOfFood from "../img/onboarding/books/in-defense-of-food.webp";
import IntelligentInvestor from "../img/onboarding/books/intelligent-investor.webp";
import NeverSplit from "../img/onboarding/books/never_split_the_difference.webp";
import NFT from "../img/onboarding/books/NFTs.webp";
import PsychologyMoney from "../img/onboarding/books/psychology-of-money.webp";
import ReadPeople from "../img/onboarding/books/read-people-like-a-book.webp";
import Sapiens from "../img/onboarding/books/sapiens.webp";
import SPQR from "../img/onboarding/books/SPQR.webp";
import StarFormation from "../img/onboarding/books/star-formation-and-black-holes.webp";
import Obstacle from "../img/onboarding/books/the-obstacle-is-the-way.webp";
import ThinkMonk from "../img/onboarding/books/think-like-a-monk.webp";
import ThinkingFast from "../img/onboarding/books/thinking-fast-and-slow.webp";
import UnwindingAnxiety from "../img/onboarding/books/unwinding-anxiety.webp";
import WayPersuade from "../img/onboarding/books/way-you-persuade.webp";
import AiSmarter from "../img/onboarding/books/when-will-ai-be-smarter-than-us.webp";
import ScienceOfHappiness from "../img/onboarding/books/science-of-happiness.webp";
import PsychologyOfHumanRelationships from "../img/onboarding/books/psychology-of-human-relationships.webp";

export const attributionSource = "attributionSource";

export enum QUESTION_TYPES {
  Animation,
  Image,
  Text,
  Select,
  MultiSelect,
  SpecialSelect,
  Thumb,
}

export enum ContentCategory {
  Philosophy = "Philosophy",
  Psychology = "Psychology",
  ScienceAndTech = "Science & Technology",
  Economics = "Economics",
  Productivity = "Productivity",
  Leadership = "Leadership",
  SelfHelp = "Self-Help",
  History = "History",
  HealthAndWellness = "Health & Wellness",
  Business = "Business",
}

export type OptionType = {
  label: string;
  image?: string;
};

export type DataT =
  | AnimationT
  | ImageT
  | TextT
  | SelectT
  | MultiselectT
  | SpecialSelectT
  | ThumbQuestionT;

type AnimationT = {
  id: string;
  type: QUESTION_TYPES.Animation;
  title?: string;
  asset: string;
  hardcode?: boolean;
};

type ImageT = {
  id: string;
  type: QUESTION_TYPES.Image;
  title?: string;
  asset: string;
  alt: string;
  background?: string;
};

type TextT = {
  id: string;
  type: QUESTION_TYPES.Text;
  title: string;
  background: string;
};

type SelectT = {
  id: string;
  type: QUESTION_TYPES.Select;
  title: string;
  subtitle?: string;
  key: string;
  options: OptionType[];
};

type MultiselectT = {
  id: string;
  type: QUESTION_TYPES.MultiSelect;
  title: string;
  subtitle: string;
  key: string;
  options: OptionType[];
};

type SpecialSelectT = { id: string; type: QUESTION_TYPES.SpecialSelect; key: string };

export const onboardingQuestions: DataT[] = [
  {
    id: "lucidIsaNewWayTolearn",
    type: QUESTION_TYPES.Animation,
    asset: Onboarding1,
    hardcode: true,
  },
  {
    id: "lucidHelps",
    type: QUESTION_TYPES.Animation,
    asset: Onboarding2,
  },
  {
    id: "toGetStartedAnswerQuestions",
    type: QUESTION_TYPES.Text,
    title: "To get started, answer a few questions to personalize your experience.",
    background: "#EC6B50",
  },
  {
    id: "whichTopicsDoYouWant_3",
    type: QUESTION_TYPES.MultiSelect,
    title: "Which of these topics interest you?",
    subtitle: "(Select at least one)",
    key: "whichTopicsDoYouWant_3",
    options: [
      {
        label: ContentCategory.History,
        image: images.HistoryTopic,
      },
      {
        label: ContentCategory.Philosophy,
        image: images.PhilosophyTopic,
      },
      {
        label: ContentCategory.ScienceAndTech,
        image: images.ScienceTopic,
      },
      {
        label: ContentCategory.Productivity,
        image: images.ProductivityTopic,
      },
      {
        label: ContentCategory.Economics,
        image: images.EconomicsTopic,
      },
      {
        label: ContentCategory.Psychology,
        image: images.PsychologyTopic,
      },
      {
        label: ContentCategory.Business,
        image: images.BusinessTopic,
      },
      {
        label: ContentCategory.Leadership,
        image: images.LeadershipTopic,
      },
      {
        label: ContentCategory.SelfHelp,
        image: images.SelfHelpTopic,
      },
      {
        label: ContentCategory.HealthAndWellness,
        image: images.HealthTopic,
      },
    ],
  },
  {
    id: "lucidHelpsYouLearn",
    type: QUESTION_TYPES.Animation,
    title:
      "Imprint helps you learn these topics more effectively, with a unique format optimized for bite-sized sessions.",
    asset: Onboarding3,
  },
  {
    id: "howMuchTime_3",
    type: QUESTION_TYPES.Select,
    title: "In the last week, how much time have you spent reading nonfiction books?",
    key: "howMuchTime_3",
    options: [
      { label: "3+ hours" },
      { label: "1-3 hours" },
      { label: "Under 1 hour" },
      { label: "I did not read this week" },
    ],
  },
  {
    id: "booksRequireTime",
    type: QUESTION_TYPES.Animation,
    title: "It's hard to set aside as much time as you'd like for books and courses.",
    asset: images.NoTime,
  },
  {
    id: "lucidIsBiteSized",
    type: QUESTION_TYPES.Animation,
    title: "Imprint’s bite-sized chapters make it easy to learn in just a few minutes at a time.",
    asset: images.FindTime,
  },
  // {
  //   type: QUESTION_TYPES.Image,
  //   asset: images.FindingGold,
  //   alt: "This app has been like finding gold.",
  //   background: "#3A9776",
  // },
  {
    id: "quoteRevamped_1",
    type: QUESTION_TYPES.Image,
    asset: images.NewKnowledge,
    alt:
      "I'm learning during my lunch breaks instead of scrolling through Instagram and I use my new knowledge during my work day.",
    background: "#3A9776",
  },
  {
    id: "learningPathsMaps",
    type: QUESTION_TYPES.Select,
    title: "Choose your path!",
    subtitle: "Pick the goal most important to you. You can always change it later.",
    key: "learningPathsMaps",
    options: [
      {
        label: "Be more productive",
        image: images.ProductivePath,
      },
      {
        label: "Understand finance and investing",
        image: images.FinancePath,
      },
      {
        label: "Build leadership skills",
        image: images.LeadershipPath,
      },
      {
        label: "Resolve conflict",
        image: images.ConflictPath,
      },
      {
        label: "Make smarter decisions",
        image: images.DecisionsPath,
      },
      {
        label: "Explore human history",
        image: images.HistoryPath,
      },
      {
        label: "Succeed in business",
        image: images.BusinessPath,
      },
      {
        label: "Find meaning and joy",
        image: images.JoyPath,
      },
      {
        label: "Communicating effectively",
        image: images.CommunicationPath,
      },
      {
        label: "Manage stress",
        image: images.StressPath,
      },
      {
        label: "Heal trauma and grow resilience",
        image: images.ResiliencePath,
      },
    ],
  },
  {
    id: "learningIsTheBeginning",
    type: QUESTION_TYPES.Image,
    title:
      "Learning is only the beginning. Imprint makes it easy to apply what you learn to your life.",
    asset: images.LearnRememberApply,
    alt:
      "Learning is only the beginning. Imprint makes it easy to apply what you learn to your life.",
  },
  {
    id: "usersSayLucidImprovedLife",
    type: QUESTION_TYPES.Image,
    asset: images.FourOutOfFive,
    alt: "4 out of 5 users say Imprint has improved their life",
    background: "#EC6B50",
  },
  {
    id: "setGoal_2",
    type: QUESTION_TYPES.SpecialSelect,
    key: "setGoal_2",
    // title: "Set a goal for your first week!",
    // key: "goal_first_week",
  },
  {
    id: "quoteRevamped_2",
    type: QUESTION_TYPES.Image,
    asset: images.BestThing,
    alt: "This app is one of the best things you can do for yourself.",
    background: "#3A9776",
  },
];

export type ThumbQuestionT = {
  type: QUESTION_TYPES.Thumb;
  id: string;
  asset: string;
  title: string;
  author?: string;
};

export const attributionQuestion: DataT = {
  id: "attributionSurvey",
  type: QUESTION_TYPES.Select,
  title: "Where did you hear about Imprint?",
  key: attributionSource,
  options: [
    { label: "Instagram" },
    { label: "App Store" },
    { label: "Google Play Store" },
    { label: "TikTok" },
    { label: "YouTube" },
    { label: "Google Search" },
    { label: "Friend/Family" },
    { label: "Facebook" },
    { label: "X (formerly Twitter)" },
    { label: "Podcast" },
    { label: "Newsletter" },
    { label: "Other" },
  ],
};

export const thumbsData: {
  [key in ContentCategory]: ThumbQuestionT[];
} = {
  [ContentCategory.Philosophy]: [
    {
      type: QUESTION_TYPES.Thumb,
      id: "-NVpoVvj5wOL10pwgKhQ",
      asset: EssentialPhilosophy,
      title: "Essential Philosophy: Theories and Thinkers",
      author: "John Kaag",
    },
    {
      type: QUESTION_TYPES.Thumb,
      id: "-N7RjzcNg7zm7ePA3B0l",
      asset: Obstacle,
      title: "The Obstacle is the Way",
      author: "Ryan Holiday",
    },
    {
      type: QUESTION_TYPES.Thumb,
      id: "-MYt77VCDMOAPuiPXX7O",
      asset: ThinkMonk,
      title: "Think Like a Monk",
      author: "Jay Shetty",
    },
  ],
  [ContentCategory.Psychology]: [
    {
      type: QUESTION_TYPES.Thumb,
      id: "-NkGX_Z5HhbAR5A9KMOq",
      asset: ScienceOfHappiness,
      title: "The Science of Happiness",
      author: "Jason Mitchell",
    },
    {
      type: QUESTION_TYPES.Thumb,
      id: "-N9HvJ9aC-G9Dc9-pRSH",
      asset: ReadPeople,
      title: "Read People like a Book",
      author: "Patrick King",
    },
    {
      type: QUESTION_TYPES.Thumb,
      id: "-M0ZLX_9LY13H6SGiLaY",
      asset: ThinkingFast,
      title: "Thinking, Fast and Slow",
      author: "Daniel Kahneman",
    },
  ],
  [ContentCategory.ScienceAndTech]: [
    {
      type: QUESTION_TYPES.Thumb,
      id: "-NY4pBVWATq5SaWl_iig",
      asset: AiSmarter,
      title: "When Will AI be Smarter than Us?",
    },
    {
      type: QUESTION_TYPES.Thumb,
      id: "-MxdZ2ZTugM11eEiy7sj",
      asset: StarFormation,
      title: "Star Formation and Black Holes",
    },
    {
      type: QUESTION_TYPES.Thumb,
      id: "-NFjc3CFYaVVv0Zn1CAd",
      asset: HomoDeus,
      title: "Homo Deus",
      author: "Yuval Noah Harari",
    },
  ],
  [ContentCategory.Economics]: [
    {
      type: QUESTION_TYPES.Thumb,
      id: "-MfY8X-DxtHSym7x6TEs",
      asset: BeginnersGuide,
      title: "A Beginner's Guide to the Stock Market",
      author: "Matthew Kratter",
    },
    {
      type: QUESTION_TYPES.Thumb,
      id: "-MsWud4g9UKkDTJOHPRJ",
      asset: NFT,
      title: "NFTs: A Visual Guide",
    },
    {
      type: QUESTION_TYPES.Thumb,
      id: "-MkTgczf_NPC05Ppebtw",
      asset: IntelligentInvestor,
      title: "The Intelligent Investor",
      author: "Benjamin Graham",
    },
  ],
  [ContentCategory.Productivity]: [
    {
      type: QUESTION_TYPES.Thumb,
      id: "-MEcmb1w3s-SiOACEv1f",
      asset: AtomicHabits,
      title: "Atomic Habits",
      author: "James Clear",
    },
    {
      type: QUESTION_TYPES.Thumb,
      id: "-MggH-aayNUUwd5hBrqj",
      asset: Flow,
      title: "Flow",
      author: "Mihaly Csikszentmihalyi",
    },
    {
      type: QUESTION_TYPES.Thumb,
      id: "-N9qbiuqMCC5w9GaLNWH",
      asset: SecondRule,
      title: "The 5 Second Rule",
      author: "Mel Robbins",
    },
  ],
  [ContentCategory.Leadership]: [
    {
      type: QUESTION_TYPES.Thumb,
      id: "-MODsQGlKEywnJhQSKLo",
      asset: WinFriends,
      title: "How to Win Friends and Influence People",
      author: "Dale Carnegie",
    },
    {
      type: QUESTION_TYPES.Thumb,
      id: "-MAmPNu7bD2Cmlug0l7c",
      asset: NeverSplit,
      title: "Never Split the Difference",
      author: "Christopher Voss and Tahl Raz",
    },
    {
      type: QUESTION_TYPES.Thumb,
      id: "-Mp794ICskkFaWPbekLS",
      asset: CrucialConversations,
      title: "Crucial Conversations",
      author: "Joseph Grenny, Kerry Patterson, et al.",
    },
  ],
  [ContentCategory.SelfHelp]: [
    {
      type: QUESTION_TYPES.Thumb,
      id: "-NI35ewKyW2R5brBRuDg",
      asset: EmotionalIntelligence,
      title: "Lessons in Leadership: Emotional Intelligence",
      author: "",
    },
    {
      type: QUESTION_TYPES.Thumb,
      id: "-N3yxBl2fyDLEJDuUKKC",
      asset: UnwindingAnxiety,
      title: "Unwinding Anxiety",
      author: "Judson Brewer",
    },
    {
      type: QUESTION_TYPES.Thumb,
      id: "-NkGX_Z5HhbAR5A9KMOq",
      asset: ScienceOfHappiness,
      title: "The Science of Happiness",
      author: "Jason Mitchell",
    },
  ],
  [ContentCategory.History]: [
    {
      type: QUESTION_TYPES.Thumb,
      id: "-Ma3DB7EqOP8FcZ6DArx",
      asset: SPQR,
      title: "SPQR",
      author: "Mary Beard",
    },
    {
      type: QUESTION_TYPES.Thumb,
      id: "-MkrXm3BSTVm5NJBeeIR",
      asset: Sapiens,
      title: "Sapiens",
      author: "Yuval Noah Harari",
    },
    {
      type: QUESTION_TYPES.Thumb,
      id: "-MySPuF1BsPtInHCYMjR",
      asset: GunsGermsSteel,
      title: "Guns, Germs, and Steel",
      author: "Jared Diamond",
    },
  ],
  [ContentCategory.HealthAndWellness]: [
    {
      type: QUESTION_TYPES.Thumb,
      id: "-MHmFNJ4bTgXrRInH3Iu",
      asset: DefenseOfFood,
      title: "In Defense of Food",
      author: "Michael Pollan",
    },
    {
      type: QUESTION_TYPES.Thumb,
      id: "-N520AdIVfV_HQ2V4ke1",
      asset: GutBacteria,
      title: "You Are What Your Gut Bacteria Eat",
      author: "Judson Brewer",
    },
    {
      type: QUESTION_TYPES.Thumb,
      id: "-N3yxBl2fyDLEJDuUKKC",
      asset: UnwindingAnxiety,
      title: "Unwinding Anxiety",
      author: "Judson Brewer",
    },
  ],
  [ContentCategory.Business]: [
    {
      type: QUESTION_TYPES.Thumb,
      id: "-NQaCYUQ49TQdWeeRbWo",
      asset: WayPersuade,
      title: "Improve The Way You Persuade",
    },
    {
      type: QUESTION_TYPES.Thumb,
      id: "-LryZhKsrCCS4Zn24XY9",
      asset: SevenHabits,
      title: "The 7 Habits of Highly Effective People",
      author: "Steven R. Covey",
    },
    {
      type: QUESTION_TYPES.Thumb,
      id: "-MKLsooJgeN_3ChyY5tU",
      asset: PsychologyMoney,
      title: "The Psychology of Money",
      author: "Morgan Housel",
    },
  ],
};

export const globalThumbQuestions: ThumbQuestionT[] = [
  {
    type: QUESTION_TYPES.Thumb,
    id: "-NgdZlTnsl1JcwxfJKba",
    asset: PsychologyOfHumanRelationships,
    title: "Interpersonal Dynamics: The Psychology of Human Relationships",
  },
];
