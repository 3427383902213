import { fetchAndActivate, getValue } from "firebase/remote-config";
import { remoteConfig } from "./firebase";

export const initializeRemoteConfig = async () => {
  if (!remoteConfig) {
    console.error("NO REMOTE CONFIG FOUND. NOT IN BROWSER");
    return;
  }

  // USED FOR DEVELOPMENT
  // remoteConfig.settings.minimumFetchIntervalMillis = 1500;
  remoteConfig.defaultConfig = {
    enableAltPrepaymentPage: false,
  };

  try {
    const isFetched = await fetchAndActivate(remoteConfig);
    if (isFetched) {
      console.log("fetched configs successfully activated");
    } else {
      console.log("fetched configs were already activated");
    }

    return true;
  } catch (err) {
    console.error("Remote Config failed to activate:", err);
    return false;
  }
};

export enum REMOTE_CONFIG_BOOLEAN_KEYS {
  enableAltPrepaymentPage = "enableAltPrepaymentPage", // DEPRECATED
}

export enum REMOTE_CONFIG_STRING_KEYS {
  showAltPrice = "showAltPrice",
  landingPageVariation = "landingPageVariation",
  onboardingVariation = "onboardingVariation", // DEPRECATED
  familyPlanVariation = "familyPlanTest",
  stripePriceTest = "stripePriceTest",
}

export const getRemoteConfigBoolean = (key: REMOTE_CONFIG_BOOLEAN_KEYS) => {
  if (!remoteConfig) {
    return;
  }

  return getValue(remoteConfig, key).asBoolean();
};

export const getRemoteConfigString = (key: REMOTE_CONFIG_STRING_KEYS) => {
  if (!remoteConfig) {
    return;
  }

  return getValue(remoteConfig, key).asString();
};
